import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const NewsMVGives2024 = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);

        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-news-header.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Latest Events & News</h1>
                    <h4 className='fade-in-up-load'>
                    Learn more about all the latest events and news from us at Oneida County History Center.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section news-subpage'>
                <div className='container'>
                    <div>
                        <a href="/news" className='main-button'>
                            <i className='fa fa-arrow-left'></i> Back to All News
                        </a>
                    </div>
                    <img decoding='async' loading='lazy' alt='news event'  className="main-img" src="/assets/mvgives_2024.jpg" />
                    <br/><br/>
                    <h2 className='fade-in-up'>History Center to Participate in Mohawk Valley Gives!</h2>
                    <p className='fade-in-up'>
                    On September 20, the Community Foundation of Herkimer and Oneida Counties and UpMobility Foundation will host the third annual “Mohawk Valley Gives,” a 24-hour community-wide giving day to benefit nonprofit organizations serving the Mohawk Valley. Oneida County History Center is proud to join dozens of worthy local organizations who are participating.
                    </p>
                    <p>Help us raise $4,000 to upgrade to PastPerfect Web Edition and show your support for local history. Past Perfect is a collections and contact management software designed for museums. It holds our catalog records and organizes our artifacts, documents, photographs, and library books. This important tool is used by staff, volunteers, and visitors to access information, documents, and objects in the research library. As a not-for-profit that depends on contributions, we appreciate gifts of any size! </p>
                    <p>Visit our MV Giving-Day profile at <a href="http://www.givemv.org/organizations/oneida-county-history-center">www.givemv.org/organizations/oneida-county-history-center</a> starting September 4 to learn more!                    </p>
                </div>
            </section>
        </div>
    );
}

export default NewsMVGives2024;