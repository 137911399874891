import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const ZachLewis = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Sat. November 9 | Utica</h5>
                    <h1>Zach Lewis Lecture & Book Signing  </h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/zachlewis.jpg"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                    Saturday, November 9 at 2:00 P.M. <br/>
                    Utica, 1608 Genesee St, Utica, NY 13502, USA
                    </p><br/>
                    <h2>About the Event</h2>
                    <p>
                    Clinton native Zach Lewis, an educator, journalist, and photographer, explores the area's heritage, highlighting the evolving landscape and why he believes that "all roads lead to Clinton." He’ll discuss and sign copies of his new book Clinton and Kirkland: Past & Present.
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default ZachLewis;