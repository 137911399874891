import React from 'react';
import { useEffect } from 'react';
import { FadeIn } from '../FadeIn';
import { LoadFadeIn } from '../LoadFadeIn';
import ReactGA from 'react-ga';



const Programs = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div id="programs-page">
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/visit/programs/TEMP-programs.webp' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className="fade-in-up-load">Programs & Events</h1>
                    <h4 className="fade-in-up-load">
                    No matter who you are, there's always something new to learn at Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section'>
                <div className='container'>
                    <h2>Upcoming Events</h2>
                    <div className='row'>
                    <div className="col-lg-4">
                        <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/zogby.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Premiere Documentary about John Zogby                                    </h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, October 19, 2024
                                    </p>
                                    <a href="/zogby" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/losthistory.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">A Lost History, Recovered                                    </h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, October 26, 2024
                                    </p>
                                    <a href="/lost-history" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                        <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/tuesday.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Tuesday Night Big Band                                    </h4>
                                    <p class="card-text fade-in-up">
                                    Tuesday, October 29, 2024
                                    </p>
                                    <a href="/tuesday-night-big-band" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div className='row'>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/gerritt.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Revealing the Whole Story of Gerritt Smith                                   </h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, November 2, 2024
                                    </p>
                                    <a href="/gerritt-smith" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/zachlewis.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Zach Lewis Lecture & Book Signing                                    </h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, November 9, 2024
                                    </p>
                                    <a href="/zach-lewis" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                        <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/wampum.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Wampum Workshop                                    </h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, November 16, 2024
                                    </p>
                                    <a href="/wampum" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </section>
            <section className='programs-vids-section container'>
                <div className='container calendar'>
                    <div className='row top'>
                        <div className='col-lg-8'>
                            <h2>View the <span>calendar</span> for more upcoming events and programs!</h2>
                        </div>
                        <div className='col-lg-4'>
                        <a className="video-button" 
                                href="/events"
                                aria-label='calendar'
                                target='_blank'
                            >OCHC Calendar</a>                          
                        </div>
                    </div>
                </div>
            </section>
            <section className='visit-us-section'>
                <div className='container'>
                    <h2>Past Events</h2>
                    <div className='row'>
                    <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/bearsstoryhour.png"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Bears! Story Hour at the History Center                                    </h4>
                                    <p class="card-text fade-in-up">
                                    Wednesday, September 18, 2024
                                    </p>
                                    <a href="/bears-story-hour" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/butterfield.png"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Daniel Butterfield Lecture & Book Signing                                    </h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, September 21, 2024
                                    </p>
                                    <a href="/butterfield" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                        <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/oneida_community.png"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">The Story of Extraordinary Women in the Oneida Community                                    </h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, September 28, 2024
                                    </p>
                                    <a href="/oneida-community" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </section>
            <section className='programs-vids-section'>
                <div className='container'>
                    <div className='row top'>
                        <div className='col-lg-6'>
                            <h2>Learn More on Our Youtube Channel!</h2>
                        </div>
                        <div className='col-lg-6'>
                            <a className="video-button" 
                                href="https://www.youtube.com/channel/UCxL6DtlD7yI6ioAsD7z9Oxg"
                                aria-label='Newsletter'
                                target='_blank'
                            >OCHC Youtube</a>                        
                        </div>
                    </div>
                    <div className="row"> 
                        <div className="video-body col-lg-3">
                            <iframe src="https://www.youtube.com/embed/rdlMuOtOMmI?si=IXvgAIKAJMp3rl0M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>    
                        <div className="video-body col-lg-3">
                            <iframe src="https://www.youtube.com/embed/T2pI7lArnak?si=qSFgQjH0ki4NeiMB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                        
                        </div> 
                        <div className="video-body col-lg-3">
                            <iframe src="https://www.youtube.com/embed/8KSf7v-GqDc?si=h4axbzZ7BCaLSqMO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div> 
                        <div className="video-body col-lg-3">
                            <iframe src="https://www.youtube.com/embed/8H29_BOnHY4?si=5zBRdYM2petEqePP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>   
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Programs;