import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../LoadFadeIn';
import { FadeIn } from '../FadeIn';
import ReactGA from 'react-ga';

const News = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);

        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div id="news-page">
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/about/news/TEMP-news-header.webp' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Latest Events & News</h1>
                    <h4 className='fade-in-up-load'>
                    Learn more about all the latest events and news from us at Oneida County History Center.
                    </h4>
                </div>
            </section>
            <section className='news-section'>
                <div className='news-body'>
                    <div class="card news-card top-news">
                        <img decoding='async' loading='lazy' alt='news event' class="card-img-top" src="assets/halloffame2024.jpg" />
                        <div class="card-body">
                            <h4 class="card-title fade-in-up">Announcing the Historical Hall of Fame and Living Legends Class of 2024!</h4>
                            <p class="card-text fade-in-up">
                            The Oneida County Historical Hall of Fame has named four individuals to its 2024 inductee class: Dr. John Cochran, Army Surgeon General and physician to George Washington; Charles A. Gaetano, entrepreneur and philanthropist; Chauncey Goodrich, Godfather of all modern potatoes; and ...
                            </p>
                            <a href="/news-hall-of-fame-2024" class="card-link">Learn More <i className='fa fa-arrow-right'></i></a>
                        </div>
                    </div>
                </div>
                <div className="row container"> 
                    <div className="col-lg-6">
                        <div className='news-body'>
                            <div class="card news-card">
                                <img decoding='async' loading='lazy' alt='news event' class="card-img-top" src="/assets/mvgives_2024.jpg" />
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">History Center to Participate in Mohawk Valley Gives!</h4>
                                    <p class="card-text fade-in-up">
                                    On September 20, the Community Foundation of Herkimer and Oneida Counties and UpMobility Foundation will host the third annual “Mohawk Valley Gives,” a 24-hour community-wide giving day to benefit nonprofit organizations serving the ...
                                    </p>
                                    <a href="/news-mv-gives-2024" class="card-link">Learn More <i className='fa fa-arrow-right'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className='news-body'>
                            <div class="card news-card">
                            <img decoding='async' loading='lazy' alt='news event' class="card-img-top" src="assets/about/news/ochctelethon23.jpg" />
                            <div class="card-body">
                                <h4 class="card-title fade-in-up">Save the Date! History Center Telethon is May 15!</h4>
                                <p class="card-text fade-in-up">
                                Oneida County History Center is excited to announce its 19th Annual Telethon presented by Bank of Utica! This signature fundraising event will be held on Wednesday, May 15, 2024, from 5 - 8 p.m. Tune in to the live broadcast on WUTR-TV or watch the live... 
                                </p>
                                <a href="/news-telethon-2024" class="card-link">Learn More <i className='fa fa-arrow-right'></i></a>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row container"> 
                    <div className="col-lg-6">
                        <div className='news-body'>
                            <div class="card news-card">
                                <img decoding='async' loading='lazy' alt='news event' class="card-img-top" src="/assets/about/news/utica_boo.jpg" />
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">History Center Seeks Auditions for Utica Boo!</h4>
                                    <p class="card-text fade-in-up">
                                    Oneida County History Center is seeking actors for its upcoming stage production of “Utica Boo!” A haunted whodunnit featuring ghosts from historical Utica’s past. Local author, and paranormal investigator, Dennis Webster, wrote this fun and interactive ....
                                    </p>
                                    <a href="/news-utica-boo" class="card-link">Learn More <i className='fa fa-arrow-right'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className='news-body'>
                            <div class="card news-card">
                                <img decoding='async' loading='lazy' alt='news event' class="card-img-top" src="assets/about/news/Five_Families_Band_Photo.jpeg" />
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Monk Rowe Awarded Grant for History Concerts</h4>
                                    <p class="card-text fade-in-up">
                                    Monk Rowe & the Five Families Ensemble have partnered with Oneida County History Center to present two concerts in 2024. The Ensemble offers a unique instrumentation comprised of the four instrumental families (strings, woodwinds, brass, and percussion)... 
                                    </p>
                                    <a href="/news-five-families" class="card-link">Learn More <i className='fa fa-arrow-right'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default News;